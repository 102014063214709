
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

$numberOfItems: var(--number-of-items);

.list {
    display: flex;
    overflow-x: scroll;
    margin: 0 calc(#{$vpn-page-padding-x} * -1);
    padding: 0 $vpn-page-padding-x;
    @apply py-10 px-3 md:justify-center;
    .list-item {
        width: 40%;
        background-color: white;
        box-shadow: 0 2px 25px rgba(black, 0.11);
        margin-right: 20px;
        border-radius: 6px;
        position: relative;
        max-width: 170px;
        transition: all 0.3s ease;
        .inner {
            height: 0;
            padding-bottom: 100%;
            a {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: absolute;
                top: 0%;
                left: 0;
                height: 100%;
                @apply py-3 px-5;
                .selling-point {
                    border-radius: 2px;
                    transition: all 0.2s ease-in-out;
                    background-color: rgba($vpn-comparison-melon, 0.1);
                    color: $vpn-comparison-melon;
                    @apply text-xs py-1 px-2;
                }
                .logo {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    img {
                        height: 40px;
                        width: auto;
                        max-width: 120px;
                        object-fit: contain;
                        object-position: bottom;
                        @include lg {
                            height: 50px;
                            max-width: 150px;
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &:hover {
            transform: translateY(5px);
            box-shadow: 0 0px 5px rgba(black, 0.11);
            a {
                .selling-point {
                    background-color: $vpn-comparison-melon;
                    color: white;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
        @include lg {
            width: calc(100% / #{$numberOfItems});
            flex: unset;
            max-width: 200px;
        }
    }
    @include lg {
        overflow-x: visible;
    }
}
